<template>
  <Layout>
    <template v-slot:content>
      <SidebarAddDish
        v-if="showAddSideBar"
        :dish="newDish"
        @closeModal="toggleAddDish"
        @reloadDishes="reloadDishes"
      ></SidebarAddDish>
      <SidebarUpdateDish
        v-if="showUpdateSideBar"
        :dish="activeDish"
        @closeModal="toggleUpdateDish"
        @reloadDishes="reloadDishes"
      ></SidebarUpdateDish>
      <div class="px-4 md:px-20 h-screen w-full py-8">
        <h1 class="text-4xl text-primary">{{ $t("my-dishes") }}</h1>
        <form
          action="post"
          class="w-full md:w-2/4 mt-4"
          @keyup.enter="search"
          @submit.prevent="search"
        >
          <input
            type="text"
            id="term"
            name="term"
            v-model="term"
            @keyup="checkEmpty"
            placeholder="Suche nach einem Gericht ..."
            class="w-full shadow border border-gray-300 rounded px-2 py-1 text-gray-700"
          />
        </form>
        <div class="mt-4 rounded bg-white shadow">
          <div class="p-4 border-b flex items-center justify-end">
            <button @click="toggleAddDish" class="btn-table">
              <svg
                class="fill-current h-4 w-4 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              {{ $t("add") }}
            </button>
          </div>
          <div class="overflow-x-scroll">
            <table class="table-fixed min-w-full">
              <thead>
                <tr class="bg-gray-100">
                  <th
                    class="uppercase text-primary text-left font-normal leading-loose tracking-wide py-2 px-4 md:w-1/3"
                  >
                    {{ $t("dish") }}
                  </th>
                  <th
                    class="uppercase text-primary text-left font-normal leading-loose tracking-wide py-2 px-4 md:w-1/3"
                  >
                    {{ $t("source") }}
                  </th>
                  <th
                    class="uppercase text-primary text-left font-normal leading-loose tracking-wide py-2 px-4 md:w-2/4"
                  >
                    {{ $t("categories") }}
                  </th>
                  <th
                    class="text-left leading-loose tracking-wide py-2 md:w-16"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dish in dishes" :key="dish._id">
                  <td class="px-4 py-2 border-b">{{ dish.name }}</td>
                  <td class="px-4 py-2 border-b text-gray-500">
                    <span v-if="dish.isReference">{{ dish.source }}</span>
                    <a
                      v-else
                      :href="dish.source"
                      target="_blank"
                      class="hover:text-primary hover:underline flex items-center"
                    >
                      <svg
                        class="fill-current w-4 h-4 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        />
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        />
                      </svg>
                      {{ dish.source }}
                    </a>
                  </td>
                  <td class="px-4 py-2 border-b flex-wrap">
                    <span
                      class="px-3 py-1 text-gray-900 rounded mt-2 text-sm mr-2"
                      v-for="category in dish.categories"
                      :key="category._id"
                      :style="{ background: category.color }"
                    >
                      {{ category.name }}
                    </span>
                  </td>
                  <td class="px-4 py-2 border-b">
                    <svg
                      class="fill-current h-4 w-4 hover:text-primary cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      @click="toggleDropdown(dish._id)"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      />
                    </svg>
                    <div
                      v-if="showDropdown === dish._id"
                      class="absolute rounded border z-40 bg-white right-6 md:right-24 mt-1"
                    >
                      <ul class="w-40">
                        <li
                          class="py-2 px-4 flex items-center rounded-t hover:bg-primary hover:text-white cursor-pointer border-b"
                          @click="toggleUpdateDish(dish)"
                        >
                          <svg
                            class="fill-current w-4 h-4 mr-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>{{ $t("edit") }}</span>
                        </li>
                        <li
                          class="py-2 px-4 flex items-center rounded-b hover:bg-primary hover:text-white cursor-pointer"
                          @click="deleteDish(dish)"
                        >
                          <svg
                            class="fill-current w-4 h-4 mr-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>{{ $t("delete") }}</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../Layouts/Layout";
import SidebarUpdateDish from "../components/SidebarUpdateDish";
import SidebarAddDish from "@/components/SidebarAddDish";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Dishes",
  components: { Layout, SidebarAddDish, SidebarUpdateDish },
  setup() {
    const dishes = ref([]);
    const term = ref("");
    const showDropdown = ref(false);
    const showAddSideBar = ref(false);
    const showUpdateSideBar = ref(false);
    const activeDish = ref({});
    const router = useRouter();
    const newDish = ref({
      name: "",
      source: "",
      selectedCategories: [],
    });

    onMounted(async () => (dishes.value = await getDishes()));

    const getDishes = async () => {
      try {
        const { data } = await window.axios.get("/dishes");
        return data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("authUser");
        }
      }
    };

    const reloadDishes = async () => (dishes.value = await getDishes());

    const toggleDropdown = (dishId) => {
      if (!showDropdown.value) {
        showDropdown.value = dishId;
      } else {
        showDropdown.value = false;
      }
    };

    const toggleUpdateDish = (dish) => {
      activeDish.value = dish;
      showUpdateSideBar.value = !showUpdateSideBar.value;
      showDropdown.value = !showDropdown.value;
    };

    const toggleAddDish = () => (showAddSideBar.value = !showAddSideBar.value);

    const deleteDish = async (dish) => {
      try {
        const response = await window.axios.delete("/dishes/" + dish._id);
        dishes.value.splice(dishes.value.indexOf(dish), 1);
        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const search = async () => {
      const { data } = await window.axios.post("/dishes/search", {
        term: term.value,
      });
      dishes.value = data;
    };

    const checkEmpty = async () => {
      if (term.value.length === 0) {
        dishes.value = await getDishes();
      }
    };

    return {
      dishes,
      term,
      showDropdown,
      showAddSideBar,
      showUpdateSideBar,
      activeDish,
      newDish,
      reloadDishes,
      toggleDropdown,
      toggleUpdateDish,
      toggleAddDish,
      deleteDish,
      search,
      checkEmpty,
    };
  },
};
</script>
