<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-50"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeModal')"></div>
    <div
      class="fixed top-0 right-0 z-45 h-screen w-full md:w-1/3 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div>
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeModal')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ $t("update-dish") }}</h1>
        <form class="mt-6" @submit.prevent="updateDish">
          <div class="form-block">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              type="text"
              id="name"
              name="name"
              v-model="newDish.name"
              placeholder="Name des Gerichts"
              class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
            />
          </div>
          <div class="form-block mt-4">
            <label
              for="source"
              class="block text-sm font-medium text-gray-700"
              >{{ $t("source") }}</label
            >
            <input
              type="text"
              id="source"
              name="source"
              v-model="newDish.source"
              placeholder="z.B. https://www.lecker.de / Kochbuch Lieblingsrezepte"
              class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
            />
          </div>
          <div class="form-block mt-4">
            <label
              for="category"
              class="block text-sm font-medium text-gray-700"
              >{{ $t("categories") }}</label
            >
            <Multiselect
              v-model="newDish.categories"
              mode="tags"
              placeholder="Wähle eine Kategorie"
              trackBy="name"
              label="name"
              :options="options"
              :searchable="true"
              :createTag="true"
              :appendNewTag="true"
              :object="true"
              ref="multiselect"
              @select="addColor"
            >
              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div
                  class="pl-3 pr-2 py-0.5 mr-1 -mt-1 text-gray-900 rounded text-sm flex items-center"
                  :class="option.color ? '' : 'border'"
                  :style="{ background: option.color }"
                >
                  {{ option.name }}
                  <svg
                    class="ml-2 w-3 h-3 fill-current hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    v-if="!disabled"
                    @click.prevent
                    @mousedown.prevent.stop="handleTagRemove(option, $event)"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </template>
            </Multiselect>
          </div>
          <div class="form-block mt-4">
            <button type="submit" class="btn-primary">{{ $t("save") }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";

export default {
  name: "SidebarUpdateDish",
  emits: ["closeModal", "reloadDishes"],
  props: ["dish"],
  components: { Multiselect },
  setup(props, { emit }) {
    const categories = ref([]);
    const router = useRouter();
    const options = ref([]);
    const multiselect = ref(null);
    const categoryIds = props.dish.categories.reduce((carry, category) => {
      return [
        ...carry,
        {
          value: category._id,
          name: category.name,
          color: category.color,
        },
      ];
    }, []);
    const newDish = reactive({
      name: props.dish.name,
      source: props.dish.source,
      categories: categoryIds,
    });
    const colors = [
      "#ef9a9a",
      "#b39ddb",
      "#9fa8da",
      "#90caf9",
      "#80deea",
      "#80cbc4",
      "#a5d6a7",
      "#e6ee9c",
      "#ffe082",
      "#ffcc80",
      "#ffab91",
      "#bcaaa4",
      "#b0bec5",
    ];

    onMounted(async () => {
      const { data } = await window.axios.get("/user/categories");
      categories.value = data;
      options.value = data.reduce((carry, category) => {
        return [
          ...carry,
          {
            value: category._id,
            name: category.name,
            color: category.color,
          },
        ];
      }, []);
    });

    const updateDish = async () => {
      try {
        const response = await window.axios.patch(`/dishes/${props.dish._id}`, {
          newDish,
        });

        multiselect.value.clear();
        emit("closeModal");
        emit("reloadDishes");

        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const addColor = (value) => {
      if (!value.color) {
        const randomNumber = Math.floor(Math.random() * Math.floor(13));
        const randomColor = colors[randomNumber];
        value.color = randomColor;
      }
    };

    return {
      categories,
      newDish,
      multiselect,
      options,
      updateDish,
      addColor,
    };
  },
};
</script>
